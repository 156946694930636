<div style="padding: 20px;">
  <div class="title">
    Revenue Summary
  </div>
  <div class="top20">
    <div class="top10">
      <div nz-row [nzGutter]="{ sm: 16 }" class="bottom10">
        <div nz-col nzSm="6" nzMd="6">
          <span>Currency</span>
        </div>
        <div nz-col nzSm="6" nzMd="6">
          <span>USD</span>
        </div>
      </div>
      <div nz-row [nzGutter]="{ sm: 16 }" style="margin-bottom: 5px;">
        <div nz-col nzSm="4" nzMd="4" nzOffset="12">Total</div>
        <div nz-col nzSm="4" nzMd="4" >Revenue Allocation</div>
        <div nz-col nzSm="4" nzMd="4" >Cost Allocation</div>
      </div>
      <div nz-row [nzGutter]="{ sm: 16 }"  style="margin-bottom: 15px;">
        <div nz-col nzSm="12" nzMd="12">
          <span>{{displayInfo.parentShipment?.warpId}} (Parent shipment)</span>
        </div>
        <div nz-col nzSm="4" nzMd="4">
          <span>{{displayInfo.parentShipment?.total || '0'}}</span>
        </div>
      </div>
      <div class="separator h bottom10"></div>
      <ng-container *ngFor="let child of displayInfo.legShipments">
        <div nz-row [nzGutter]="{ sm: 16 }"  style="margin-bottom: 15px;">
          <div nz-col nzSm="12" nzMd="12">
            <span>{{child.warpId}} (LEG)</span>
          </div>
          <div nz-col nzSm="4" nzMd="4">
            <span>{{child?.total || '$0'}}</span>
          </div>
          <div nz-col nzSm="4" nzMd="4">
            <span>{{child?.revenueAllocation || ''}}</span>
          </div>
          <div nz-col nzSm="4" nzMd="4">
            <span>{{child?.costAllocation || ''}}</span>
          </div>
        </div>
        <div class="separator h bottom10"></div>
      </ng-container>
      <div nz-row [nzGutter]="{ sm: 16 }"  style="margin-bottom: 15px;">
        <div nz-col nzSm="12" nzMd="12">
          <span class="grand-total">Total Revenue(USD):</span>
        </div>
        <div nz-col nzSm="4" nzMd="4">
          <span class="grand-total">{{displayInfo.total || '$0'}}</span>
        </div>
        <div nz-col nzSm="4" nzMd="4">
          <span>{{ displayInfo.totalRevenueAllocation || '' }}</span>
        </div>
        <div nz-col nzSm="4" nzMd="4">
          <span>{{ displayInfo.totalCostAllocation || '' }}</span>
        </div>
      </div>
    </div>
  </div>
</div>