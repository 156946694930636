<div class="container">
    <h3>Manual Scheduling Job</h3>
    <div class="section">
        <div class="header flex">Order : <code style="padding-left: 10px;">{{ showOrderCode(order) }}</code> <div class="hold-badge" *ngIf="onHold()">HOLD</div></div>
        <div>
            <div><span class="label short">From : </span> <span class="value">{{shipment.addrPick}}</span></div>
            <div><span class="label short">To   : </span> <span class="value">{{shipment.addrDrop}}</span></div>
        </div>
    </div>

    <div *ngFor="let item of listJobs" class="section">
        <div class="header">Created Job</div>
        <div><span class="label">Label : </span>{{ item.label }}</div>
        <div><span class="label">Mileage : </span>{{ mileage }}</div>
        <div><span class="label">Hours : </span>{{ hours }}</div>
        <div><span class="label">Link : </span><a [routerLink]="[routeAdminDispatchList, item._id]" target="_blank">Open in Dispatch</a></div>
    </div>

</div>

<div form-footer [onProgress]="inProgress"
    [nzIconCancel]="''" [canClickOK]="!inProgress" [canClickCancel]="!inProgress && !job"
    [nzIconOK]="''" [labelOK]="job ? 'Close' : 'Start'" (onOK)="onOk()"
    (onCancel)="onBtnCancel()"
    [isVisibleLeftButton]="!isAdminReadOnlyRole"
    labelLeftButton="Return to Depot"
    (onClickLeftBtn)="onReturnToDepot(order.id)"
></div>
