import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
    selector: '[un-hold-confirm]',
    templateUrl: './un-hold.html',
    styleUrls: ['index.scss']
})
export class UnHoldConfirm extends BaseComponent {
    @Input() type: string;
    @Input() id: string;
    @Input() warpId: string;

    @Input() readyCallback: (ready: boolean) => void

    _review: any = {}
    toReview: any[] = []
    get review() {
        return this._review
    }
    @Input() set review(v) {
        this.reviewStatus = {}
        this.toReview = []
        if (v.status)
        for (let k in v.status) {
            if (v.status[k] === 'NEED_REVIEW') {
                this.toReview.push(Object.assign({
                    key: k,
                    name: k.replace('_', ' ')
                }))
            }
        }

    }

    public note: string = null
    reviewStatus: any

    public getReviewInfo() {
        const info: any = {
            note: this.note,
            needReview: []
        }
        for (let k in this.reviewStatus) {
            if (this.reviewStatus[k]) {
                info.needReview.push(k)
            }
        }
        return info
    }

    onReviewUpdate() {
        for (let k of this.toReview) {
            if (!this.reviewStatus[k.key]) {
                this.readyCallback && this.readyCallback(false)
                return
            }
        }
        console.log('ready')
        this.readyCallback && this.readyCallback(true)
    }
}