<div class="form-header no-padding-bottom">
  <div class="form-title f16 medium" [innerHTML]="txtTitle"></div>
  <i class="ic-close clickable" (click)="onBtnCancel()" nz-icon nzType="close" nzTheme="outline"></i>
</div>
<div class="form-detail">
  <div class="flex bottom5">
    <div class="label">Business Number: <code (click)="getTransactionHistories($event, thirdPartyInfo?.businessNumber)">{{thirdPartyInfo?.businessNumber}}</code></div>
  </div>
  <div class="flex bottom5">
    <div class="label">Sender: <code>{{thirdPartyInfo?.sender?.name}}</code></div>
  </div>
  <div class="flex bottom5">
    <div class="label">Receiver: <code>{{thirdPartyInfo?.receiver?.name}}</code></div>
  </div>
  <div class="flex bottom5">
    <div class="label">Created At: <code>{{displayDateTimeDB(thirdPartyInfo?.createdAt)}}</code></div>
  </div>
  <ng-container *ngIf="failedAddress?.length" >
    <div class="label-mark-required">The address below is not correct, please click <a [routerLink]="getRouterLink()" (click)="closeDialog()" >here</a> to update the address</div>
    <ul>
      <ng-container *ngFor="let info of failedAddress">
        <li>{{info.type}}: {{getAddressText(info.addr)}}</li>
      </ng-container>
    </ul>
  </ng-container>
  <ng-container *ngIf="!failedAddress?.length" >
    <ng-container *ngIf="!isSend">
      <form [formGroup]="formInput" nz-form>
        <div class="shipment-selection top20">
          <div>
            <div class="flex">
              <div *ngFor="let key of ['reservationActionCode']">
                <nz-form-item>
                  <nz-form-control>
                    <div class="medium">{{getLabel(key)}} <span class="label-mark-required"></span></div>
                    <nz-radio-group [formControlName]="key">
                      <label nz-radio nzValue="A">Accept</label>
                      <label nz-radio nzValue="D">Cancel</label>
                    </nz-radio-group>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="histories214?.length">
    <div>
      <nz-table #nzTable [nzData]="histories214" nzSize="small" >
        <thead>
          <th nzColumnKey="id">Id</th>
          <th nzColumnKey="data.shipmentStatusIndicatorCode">Status</th>
          <th nzColumnKey="data.shipmentStatusOrAppointmentReasonCode">ReasonCode</th>
          <th nzColumnKey="data.date">date</th>
          <th nzColumnKey="data.time">time</th>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of nzTable.data">
            <tr>
              <td>{{data.id}}</td>
              <td>{{data?.data?.shipmentStatusIndicatorCode || data?.data?.shipmentAppointmentStatusCode}}</td>
              <td>{{data?.data?.shipmentStatusOrAppointmentReasonCode || data?.data?.shipmentStatusOrAppointmentReasonCode1}}</td>
              <td>{{data?.data?.date}}</td>
              <td>{{data?.data?.time}}</td>
            </tr>
        </ng-container>
      </nz-table>
    </div>
  </ng-container>
  
</div>

<div form-footer [onProgress]="inProgress" 
  [nzIconCancel]="''" [canClickCancel]="!inProgress" (onCancel)="onBtnCancel()"
  [nzIconOK]="''" [isVisibleOK]="!isSend || isInvoiced" [canClickOK]="needUpdate || isInvoiced" [labelCancel]="isSend ? 'Close': 'Cancel'" [labelOK]="!isInvoiced ? 'Send': 'Send Invoice Info'" (onOK)="onBtnOk()">
</div>