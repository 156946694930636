import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TAGS } from "./const";

@Component({
    selector: 'order-tag-list',
    template: "<nz-tag class='right3' *ngFor='let tag of _tags' [nzColor]='tag.color'>{{ tag.name }}</nz-tag>",
})
export class OrderTagList extends BaseComponent {
    _tags: any[] = []


    @Input() set tags(v) {
        this._tags = this.allTags.filter(it => v && v.indexOf(it.key) >= 0).map(it => Object.assign({}, it))
    }

    get allTags() {
        return TAGS
    }
}