import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";

@Component({
    selector: '[put-on-hold-confirm]',
    templateUrl: './index.html',
    styleUrls: ['index.scss']
})
export class PutOnHoldConfirm extends BaseComponent {
    @Input() type: string;
    @Input() id: string;
    @Input() warpId: string;

    public note: string = null
    public reviewStatus: any = {
        PICKUP_TIME: false,
        PICKUP_LOCATION: false,
        ITEM: false,
        DROPOFF_TIME: false,
        DROPOFF_LOCATION: false,
    }

    public getReviewInfo() {
        const info: any = {
            note: this.note,
            needReview: []
        }
        for (let k in this.reviewStatus) {
            if (this.reviewStatus[k]) {
                info.needReview.push(k)
            }
        }
        return info
    }
}