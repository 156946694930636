import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnInit, Output, Injector } from "@angular/core";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { getInjector } from "@services/index";
import { RefreshRouteLocationForMapService } from "../route-detail/refresh-route-locations-service";

@Component({
  selector: "detail-batch-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class DetailBatchInfo implements OnInit {
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  isTurnOnEditMode = Const.defaultTurnOnEditMode;
  locations = [];

  protected api: ApiService;
  protected injector: Injector;
  listClients = [];

  constructor(private refreshRouteLocationForMapService: RefreshRouteLocationForMapService) {
    this.injector = getInjector();
    this.api = this.injector.get(ApiService);
  }

  private _model: any;
  @Input("model") get model(): any {
    return this._model;
  }
  set model(value) {
    this._model = value;
  }

  ngOnInit(): void {
    this.refreshRouteLocationForMapService.data$.subscribe((res: any) => {
      this.locations = res || [];
    });
  }

  onClickEdit() {
    this.onEdit.emit();
  }

  onBtnRefreshDetailOrder() {
    this.refreshDetailOrder.emit();
  }

  onEditModeChange(event) {
    this.isTurnOnEditMode = !!event;
    this.onEdit.emit(this.isTurnOnEditMode);
  }
}
