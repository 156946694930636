<div style="padding-top: 16px">
  <button nz-button style="width: 100%; height: 32px">
    {{orderStatus}}
  </button>
  <div class="line-divider"></div>
  <div *ngIf="shouldShowEditModeBtnShipmentEntry" style="display: flex; margin-top: 16px">
    <button nz-button nzType="primary" style="width: 100%; height: 40px;" (click)="onClickEdit()">
      {{ labelEditBtn }}
    </button>
  </div>
</div>
