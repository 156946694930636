<div class="item flex" *ngFor="let item of displayInfo.items; let i = index">
  <div class="flex1">
    <div class="item-specifications-info">
      <div class="flex">
        <div class="item-name item-label right10">{{item.name}}</div>
        <div class="item-qty right5">{{item.qtyTxt}}</div>
        <div class="item-qty right5">{{item.totalWeight}}</div>
        <div class="item-qty right5" *ngIf="item.qtyNumer > 1">(Weight Per Unit: {{item.weightPerUnit}})</div>
        <div class="item-qty right5">{{item.size}}</div>
        <div class="item-qty " *ngIf="item.itemValue">{{item.itemValue}}</div>
      </div>
    </div>
    <div class="item-identification-info">
      <span class="item-id item-label">Item ID: {{item.itemId}},</span>
      <span class="item-barcode item-label">{{item.barcodes}}</span>
      <span class="item-service">Service: {{item.serivces}}</span>
    </div>
  </div>
  <div>
    <a (click)="viewContent(item?.toJSON, i)">View Content</a>
  </div>
</div>