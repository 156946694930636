import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";

@Component({
  selector: "shipment-entry-batch-detail",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss", "../../../../dialogs/dialogs.scss"],
})
export class ShipmentEntryBatchDetail extends BaseComponent {

  @Input() model;
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  public isTurnOnEditMode = Const.defaultTurnOnEditMode;

  ngOnInit(): void {
  }

  onEditModeChange(event) {
    this.isTurnOnEditMode = !!event;
  }

  onBtnRefreshDetailOrder() {
    this.refreshDetailOrder.emit();
  }
  
}
