import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseDetail } from '@app/admin/base/detail';
import { getDashboard } from "@services/index";
import { Const } from '@const/Const';
import { ResponseAdminOrderDetail } from '@wearewarp/types/rest-api/admin';
import { ModelOrderBasicInfo, ModelOrderDetail } from '@app/interfaces/order';
import { DialogService } from '@dialogs/dialog.service';
import { OrderStatus } from '@app/admin/orders/order-status';
import { Log } from '@services/log';
import { ShipmentExfreightQuote } from '@app/admin/freight-quote/shipment-exfreight-quote';
import { StringULID } from '@wearewarp/types';
import { PutOnHoldConfirm } from '../components/forms/put-on-hold-confirm';
import { UnHoldConfirm } from '../components/forms/put-on-hold-confirm/un-hold';
import { getFeatureFlags } from '@services/feature-flag.service';
import { EventsContainer } from '@app/admin/components/events';
import { TagOrderForm } from '../components/forms/order-tags';
import { ManualRoute } from '@app/admin/shipments/manual-route';
import { Utils } from '@services/utils';
import { environment } from '@env/environment';

@Component({
  selector: '[shipment-entry-detail]',
  templateUrl: './view.html',
  styleUrls: ['../../detail.scss', './index.scss']
})
export class ShipmentEntryDetail extends BaseDetail {
  @Input() orderId: StringULID;   // Dùng trongg trường hợp cần tạo component độc lập trong modal/drawer drawer(không đi qua routing url)

  get isStandaloneComponent() { return typeof this.orderId === 'string' && this.orderId.length > 0 }

  get id() {
    if (this.orderId) {
      return this.orderId;
    }
    return super.id;
  }

  protected getIdForDetailComponent(route: ActivatedRoute) {
    if (this.orderId) {
      return this.orderId;
    }
    return super.getIdForDetailComponent(route);
  }

  get shipments() {
    return this.model?.metadata?.shipments ?? [];
  }

  protected get crudEntity(): string {
    return 'orders';
  }

  constructor(
    protected activatedRoute: ActivatedRoute,
    ){
    super(activatedRoute);
  }

  showEvents: boolean = false

  ngOnInit(): void {
    super.ngOnInit();
    if (this.isStandaloneComponent) {
      this.getData();
      this.checkLogHistory();
    } else {
      setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    }

    getFeatureFlags().isFlagSetNonBlocking('SHOW_EVENT').subscribe((res) => {
      if (res) {
          this.showEvents = true;
      }
  })

  }

  ngOnDestroy() {
  }

  protected getData() {
    if (this.id && this.model?._id && this.id != this.model?._id) {
      this.model = null;
    }
    super.getData();
  }

  protected getApiUrl(): string {
    return Const.APIV2('orders');
  }

  protected canEdit(model): boolean {
    return false;
  }

  protected createFormInput(model = undefined) {
  }

  get shouldShowFirstLoading() {
    return this.onProgress && !this.model;
  }

  get isSingleMode(){
    return this.model?.shipmentEntryMode == Const.ShipmentEntryMode.single; //ShipmentEntryCreateMode.single;
  }

  isBookExfreight(item) {
    return (this.isSingleMode &&
      item.status == Const.OrderStatus.needCarrier &&
      this.model.source == Const.thirdPartyOrderSource.orderful &&
      !(item?.legIds?.length)
    );
  }

  txtCreateMode = '';

  onGetDetailSuccess(data: ResponseAdminOrderDetail): ModelOrderDetail {
    switch (data.shipmentEntryMode) {
      case Const.ShipmentEntryMode.single:
        this.txtCreateMode = 'Single Pickup/Single Dropoff';
        break;
      case Const.ShipmentEntryMode.multiDrop:
        this.txtCreateMode = 'Single Pickup/Multi Dropoff';
        break;
      case Const.ShipmentEntryMode.multiPick:
        this.txtCreateMode = 'Multi Pickup/Single Dropoff'
        break;
      case Const.ShipmentEntryMode.multiPickDrop:
        this.txtCreateMode = 'Multi Pickup/Multi Dropoff';
        break;
    }
    for (let item of data?.metadata?.shipments ?? []) {
      item.bolInfo = {_id: item.bolFileId, type: 'application/pdf'};
    }
    return {
      ...data,
      basicInfo: this.getBasicInfo(data),
    };
  }

  private getBasicInfo(data: ResponseAdminOrderDetail): ModelOrderBasicInfo {
    // BE lưu parentClientId và clientId
    let parentClientId;
    let clientId = data.clientIds[0];
    if(data.metadata?.shipments && data.metadata.shipments.length>0){
      let shipment = data.metadata.shipments[0];
      parentClientId = shipment.parentClientId;
    }
    return {
      orderId: data.id,
      clientId,
      parentClientId,
      type: data.shipmentEntryMode,
      shipmentType: data.shipmentType,
      isCrossDock: data.isCrossDock || data.shipmentType?.toUpperCase() == "LTL_CD",
      shipmentModeId: data.shipmentModeId,
      equipmentId: data.equipmentId,
      isNotAutoRouteFTL: data.isNotAutoRouteFTL,
      vehicleType: data.vehicleType,
      businessNumber: data?.businessNumber,
    }
  }

  getTrackingCrossdock(item) {
    const id = item.id;
    if (id) return [this.routeAdminShipmentList, id, 'tracking-items'];
    return null;
  }

  getTrackingLink(item) {
    const trackingCode = item?.code || item?.trackingCode;
    return `${environment.trackingWebUrl}/${trackingCode}`
  }

  onBtnEditShipmentStatus(shipment) {
    if (this.isAdminReadOnlyRole || shipment.status == Const.OrderStatus.removed) {
      return;
    }
    DialogService.openFormDialog1(OrderStatus, {
      nzComponentParams: {
        updateSuccess: (resp) => {
          this.onUpdateShipmentStatusSuccess(resp.data);
        },
        model: shipment,
        closeOnSuccess: true,
      },
      nzClassName: 'modal-no-padding',
      nzCentered: true,
    });
  }

  protected onUpdateShipmentStatusSuccess(newItem) {
    for (let i = 0; i < this.shipments.length; i++) {
      if (this.shipments[i].id == newItem.id) {
        this.shipments[i].status = newItem.status;
      }
    }
  }

  get needReview(): boolean {
    return this.model?.review?.needReview === true;
  }

  get isReviewed():boolean{
    return this.model?.review?.needReview === false;
  }

  isHaveJobId(shipment) {
    return !!(shipment?.jobIds?.length)
  }

  public addShipmentQueueConfirmation() {
    if(this.onProgress) return;
    this.confirm(
      "Once confirmed, this shipment will be moved to the Shipment Queue screen.",
      () => {
        this.addToShipmentQueue();
      },
      'Would you like to mark this shipment as "Reviewed"?'
    );
  }

  public onClickQuoteExfreight(shipment) {
    DialogService.openDialog(ShipmentExfreightQuote, {
      nzWidth: 960,
      nzMaskClosable: false,
      replaceWrapClassName: true,
      nzComponentParams: {
        shipment: shipment,
        items: this.model?.items.filter(item => shipment.itemIds.includes(item.id)) ?? [],
        onReloadData: () => {
          this.getData()
        }
      },
      nzClassName: "modal modal-xxl",
    });
  }

  private addToShipmentQueue() {
    this.startProgress();
    this.api.PUT(`${Const.APIURI_ORDERS}/${this.model.id}/status_reviewed`, null).subscribe(
      (resp) => {
        Log.d("update order is reviewed done ", resp);
        this.onUpdateSuccess(resp);
        this.model.review = resp.data?.review;
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );
  }

  onBooked(item, id) {
    item.externalId = id
    location.reload()
  }

  onUpdated() {
    location.reload()
  }

  get isOnHold(): boolean {
    return this.model.review?.needReview === true && this.model.tags && this.model.tags.indexOf('HOLD') >= 0
  }

  onPutOnHoldBtn() {
    this.modalService.create({
      nzTitle: 'Put on Hold confirmation',
      nzContent: PutOnHoldConfirm,
      nzComponentParams: {
        type: 'order',
        id: this.model.id,
        warpId: this.showOrderCode(this.model)
      },
      nzOnOk: (comp) => {
        this.putOrderOnHold(true, comp.getReviewInfo())
      }
    })
  }

  onUnOnHoldBtn() {
    let ready = false
    this.modalService.create({
      nzTitle: 'Un-Hold confirmation',
      nzContent: UnHoldConfirm,

      nzComponentParams: {
        type: 'order',
        id: this.model.id,
        warpId: this.showOrderCode(this.model),
        review: this.model.review,
        readyCallback: (v) => { ready = v }
      },
      nzOnOk: (comp) => {
        if (comp.toReview) {
          for (let k of comp.toReview) {
            if (!comp.reviewStatus[k.key]) {
              this.showErr(`Please review and confirm that ${k.name} is ready.`)
              return
            }
          }
        }
        this.putOrderOnHold(false, comp.getReviewInfo())
      }
    })
  }

  putOrderOnHold(hold: boolean, info = null) {
    this.startProgress();
    this.api.POST(`${Const.APIV2(Const.APIURI_ORDERS)}/${this.model.id}/${hold ? 'hold' : 'un-hold'}`, info).subscribe(
      (resp) => {
        this.model.review = resp.data?.review;
        this.model.tags = resp.data?.tags
        this.stopProgress();
      },
      (err) => {
        this.showErr(err);
        this.stopProgress();
      }
    );

  }

  showShipmentEventHistory(presentedShipment) {
    this.drawerService.create({
        nzContent: EventsContainer,
        nzClosable: false,
        nzContentParams: {
            type: 'SHIPMENT',
            id: presentedShipment.id
        }
    });
  }
  showOrderEventHistory() {
    this.drawerService.create({
        nzContent: EventsContainer,
        nzClosable: false,
        nzContentParams: {
            type: 'ORDER',
            id: this.model.id
        }
    });
  }

  async downloadBol(item, event) {
    let bolFileId = item.bolInfo?._id;
    let forceUpdate = false;
    if (event.ctrlKey || event.metaKey) {
      forceUpdate = true;
    };
    if (forceUpdate) {
      let url = Const.APIV2(`${Const.APIURI_SHIPMENTS}/${item.id}/bol`);
      let response = await this.api.POST(url).toPromise().catch(e => {
        this.showErr(`Error when re create File. ${e.message}`)
      });
      if (!response) return;
      let data = response.data;
      bolFileId = data.bolFileId
    };
    return this.downloadAttachedFile({ ...item.bolInfo, _id: bolFileId });
  }

  onTagsBtn() {
    let modalRef = null 
    modalRef = this.modalService.create({
      nzTitle: `Update Tags for Order ${this.showOrderCode(this.model)}`,
      nzContent: TagOrderForm,

      nzComponentParams: {
        order: this.model,
        onClose: () => modalRef.destroy()
      },
      nzFooter: null,
    })
  }

  isOrderfulFTL() {
    let order = this.model;
    return (
      (
        order?.thirdPartyInfo?.source === Const.thirdPartyClientCode.orderful ||
        order.source === Const.thirdPartyOrderSource.orderful ||
        (order?.sources ?? []).includes(Const.thirdPartyOrderSource.orderful)
      ) &&
      order.shipmentType === Const.ShipmentTypes.fullTruckLoad
    );
  }

  isOrder(item) {
    if (item.shipmentIds) return true;
    return false;
  }

  hasRoute(item): boolean {
    let jobIds = item?.metadata?.shipments?.[0]?.jobIds;
    //xử lý cho FTL
    if (item.shipmentType == Const.ShipmentTypes.fullTruckLoad) {
      if (!this.isOrder(item)) {
        return false;
      }
    }

    return Utils.isArrayNotEmpty(jobIds);
  }

  canRouteManual() {
    let order = this.model;
    if (order.exfreightInfo?.bookingResult) {
      // Đã book route với Exfreight rồi
      return false;
    }

    if (!this.hasRoute(order)) {
      // điều kiện là chưa tạo route
      return true
    }
    return false;
  }

  onBtnManualRouteShipment() {
    if (!this.canRouteManual()) {
      return;
    }
    let data = this.model;

    DialogService.openDialog(ManualRoute, {
      nzComponentParams: {
        order: data,
        onBtnRefresh: () => {
          this.onBtnRefresh();
        }
      },
      nzClassName: 'modal-no-padding',
      nzCentered: true,
    })
  }
}
