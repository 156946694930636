<div class="bottom10">
    Please confirm you want to un-hold {{ type }} <b>{{ warpId }}</b>.
</div>
<div class="bottom10" *ngIf="toReview?.length">
    Please make sure the following info have been reviewed:
    <div *ngFor="let option of toReview">
        <label nz-checkbox [(ngModel)]="reviewStatus[option.key]" (ngModelChange)="onReviewUpdate()">{{ option.name }}</label>
    </div>
</div>
<div>
    <label>Note</label>
</div>
<div>
    <textarea nz-input [nzAutosize]="{ minRows: 2, maxRows: 6 }" [(ngModel)]="note"></textarea>
</div>