import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { LocationSettings } from "@wearewarp/types/data-model";
import { Observable } from "rxjs";

@Component({
  selector: '[shipment-entry-delivery-info-settings]',
  templateUrl: './index.html',
  styleUrls: []
})
export class ShipmentEntryDeliveryInfoSettings extends BaseFormDialog1<{ settings: LocationSettings}> {

  @Input() onSave: (data: { settings: LocationSettings}) => Observable<any>;
  @Input() onRefreshDetailOrder: () => void;
  @Input() headerText = 'Location Settings';

  protected formGroupDeclaration: FormGroupDeclaration = {
    settings: { label: 'Settings' }
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  onSettingsChange(value) {
    this.onBtnSave();
  }

  onBtnSave() {
    let data = this.getFormData_JSON(true);
    this.onProgress = true;
    this.onSave(data).subscribe(
      resp => {
        this.onProgress = false;
        this.onRefreshDetailOrder();
      }, err => {
        this.showErr(err);
        this.onProgress = false;
      }
    );
  }
    
}
