import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class RefreshRouteLocationForMapService {
  private data = new BehaviorSubject([]);
  data$ = this.data.asObservable();

  changeData(data: any[]) {
    this.data.next(data)
  }


}