import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { InputHelper } from "@services/input-helper";
import { BaseComponent } from "@abstract/BaseComponent";
import { ModelOrderDetail } from "@app/interfaces/order";

@Component({
  selector: 'shipment-entry-revenue-summary',
  templateUrl: './view.html',
  styleUrls: ['index.scss']
})
export class ShipmentEntryRevenueSummary extends BaseComponent {
  public displayInfo: any = {};
  public children: any[] = []
  private _model: ModelOrderDetail;
  @Input() get model(): ModelOrderDetail {
    return this._model;
  }
  set model(value: ModelOrderDetail) {
    this._model = value;
    this.buildDisplayInfo();
  }

  ngOnInit(): void {
    this.loadData();
  }

  private loadData() {
    const shipmentId = this.model?.shipmentIds?.[0];
    if (!shipmentId) return;
    this.api.GET(`${Const.APIV2(Const.APIURI_SHIPMENTS)}/${shipmentId}/childrens`).subscribe((response) => {
      this.children = response.data.list_data;
      this.buildDisplayInfo();
    });
  }

  private buildDisplayInfo() {
    // set Currency = USD cho tổng quát vì có thể các leg có thể khác nhau Canada FX Rate, 
    const parentShipment = this.model?.metadata?.shipments?.[0];
    let total = 0;
    if (parentShipment) {
      const grandTotal = this.getTotalCost(this.model.cost);
      this.displayInfo.parentShipment = {
        warpId: this.showShipmentCode(parentShipment),
        total: InputHelper.formatMoney2(`${grandTotal}`)
      }
      total += grandTotal;
    }
    let totalRevenueAllocation = 0;
    let totalCostAllocation = 0
    if (Utils.isArrayNotEmpty(this.children)) {
      this.displayInfo.legShipments = this.children.map(leg => {
        const grandTotal = this.getTotalCost(leg.cost);
        total += grandTotal;
        const { finance } = leg
        const { revenueAllocation, costAllocation } = finance || {}
        totalRevenueAllocation += revenueAllocation || 0
        totalCostAllocation += costAllocation || 0
        return {
          warpId: this.showShipmentCode(leg),
          total: InputHelper.formatMoney2(`${grandTotal}`),
          revenueAllocation: revenueAllocation ? InputHelper.formatMoney2(`${revenueAllocation}`) : '',
          costAllocation: costAllocation ? InputHelper.formatMoney2(`${costAllocation}`) : '',
        }
      })
    }
    this.displayInfo.total = InputHelper.formatMoney2(`${total}`);
    this.displayInfo.totalRevenueAllocation = totalRevenueAllocation ? InputHelper.formatMoney2(`${totalRevenueAllocation}`) : '';
    this.displayInfo.totalCostAllocation = totalCostAllocation ? InputHelper.formatMoney2(`${totalCostAllocation}`) : '';
  }

  private getTotalCost(cost) {
    if (cost?.currency?.type  && cost.currency.type != 'USD') {
      return cost?.usdConversion || 0;
    } else {
      return cost?.grandTotal || 0;
    }
  }
    
}
