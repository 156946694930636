<div class="shipment-entry-content">
  <div style="flex: 1; margin-right: 20px;">
    <batch-equipment-info [isTurnOnEditMode]="isTurnOnEditMode" 
    [model]="model?.basicInfo" (refreshDetailOrder)="onBtnRefreshDetailOrder()"></batch-equipment-info>
    <div class="bottom20"></div>
    <route-detail-batch [isTurnOnEditMode]="isTurnOnEditMode" 
    [model]="model" (refreshDetailOrder)="onBtnRefreshDetailOrder()"></route-detail-batch>
    
    <div style="margin-top: 20px; background: #FFFFFF;">
      <shipment-entry-revenue-info-v2 [model]="model" 
      [isTurnOnEditMode]="isTurnOnEditMode"
      (refreshDetailOrder)="onBtnRefreshDetailOrder()"></shipment-entry-revenue-info-v2>
    </div>
  </div>
  <div style="width: 330px;">
    <detail-batch-info (onEdit)="onEditModeChange($event)" 
    [model]="model" (refreshDetailOrder)="onBtnRefreshDetailOrder()"></detail-batch-info>
  </div>
</div>



