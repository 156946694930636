import { BaseComponent } from '@abstract/BaseComponent';
import { Component, Input } from '@angular/core';
import { DrawerService } from '@app/drawers/drawer.service';
import { InputHelper } from '@services/input-helper';
import { Utils } from '@services/utils';
import { ViewContentItem } from '../view-content-item';
import { ShipmentItem } from '@wearewarp/types/data-model';
import { BizUtil } from '@services/biz';
@Component({
  selector: 'item-list',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ItemList extends BaseComponent {

  public displayInfo: any = {};
  @Input() set items(value) {
    this.buildDisPlayInfo(value);
  }

  private buildDisPlayInfo(items: ShipmentItem[]) {
    this.displayInfo.items = items.map((item, index) => ({
      name: this.getItemName(item, index),
      qtyNumer: item.qty || 0,
      qtyTxt: this.getItemQty(item),
      totalWeight: this.getItemTotalWeight(item),
      weightPerUnit: this.getItemWeightPerUnit(item),
      size: this.getItemSize(item),
      itemValue: this.formatItemValue(item.itemValue),
      itemId: this.getItemId(item),
      barcodes: this.getItemBarcode(item),
      serivces: this.getItemService(item),
      toJSON: item
    }));
  }

  formatItemValue(itemValue){
    return InputHelper.formatMoney2(itemValue);
  }

  getItemName(item: ShipmentItem, index: number): string {
    return item.name || ('Item ' + (index+1));
  }

  getItemQty(item: ShipmentItem): string {
    const quantity = item?.qty || this.naText
    const unit = item?.qtyUnit || this.naText
    return `${quantity} ${unit}`
  }

  getItemSize(item: ShipmentItem): string {
    return BizUtil.getItemSizeDesc(item);
  }

  getItemTotalWeight(item: ShipmentItem): string {
    const quantity = item?.qty || 1;
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${Utils.roundNumber(weight * quantity, 0)} ${unit}`
  }

  getItemWeightPerUnit(item: ShipmentItem): string {
    const weight = item?.weightPerUnit ?? 0;
    const unit = item?.weightUnit ?? '';
    return `${weight} ${unit}`
  }

  getItemId(item: ShipmentItem){
    return item?.itemId || this.naText
  }

  getItemBarcode(item: ShipmentItem) {
    if (item.barcodes?.length > 1) {
      return `Barcodes (${item.barcodes.length}): ${item.barcodes?.join(', ')}`;
    } else if (item.barcodes?.length == 1) {
      return `Barcode: ${item.barcodes[0]}`;
    } else {
      return `Barcode: ${this.naText}`;
    }
  }

  getItemService(item: ShipmentItem){
    let str = BizUtil.getItemServiceDesc(item);
    return str || this.naText
  }

  viewContent(item, index: number){
    DrawerService.openDrawer(ViewContentItem, {
      nzClosable: true,
      nzKeyboard: false,
      nzContent: null,
      // nzTitle: `${item.name ?? "Item"} Contents`,
      nzTitle: 'Item Contents',
      nzContentParams: {
        item: item,
        itemIndex: index,
      },
      nzWidth: 600,
    });
  }

  private get naText(){
    return "N/A"
  }

}
