<div *ngFor="let tag of _tags" class="bottom5">
    <label nz-checkbox [(ngModel)]="tag.selected">{{ tag.name }}</label>
</div>

<nz-divider></nz-divider>

<div>
    <button class="right10" nz-button (click)="onClose && onClose()">Close</button>
    <button nz-button nzType="primary" (click)="onSave()" [disabled]="saving">
        Confirm
    </button>
</div>