<div class="box">
  <div>
    <span class="title">Equipment</span>
    <span *ngIf="isTurnOnEditMode">
      <a class="edit-item-shipment-entry" (click)="onBtnEditEquipment()">
        <i nz-icon nzType="edit" nzTheme="outline" style="margin-right: 5px;"></i>Edit
      </a>
    </span>
  </div>

  <div style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px;">
    <div class="flex" style="align-items: center; margin-right: 100px;">
      <img alt="Customer" class="icon" src="assets/img/ic_shipment_info_person.svg" />
      <span>{{client?.name}}</span>
    </div>
    <div *ngIf="parentClient" class="flex" style="align-items: center; margin-right: 100px;">
      <img alt="Customer" class="icon" src="assets/img/ic_shipment_info_person.svg" />
      <span>Parent: {{parentClient?.name}}</span>
    </div>
    <div style="display: flex; align-items: center; margin-right: 100px;">
      <img alt="Truck type" class="icon" src="assets/img/ic_shipment_info_truck.svg" />
      <span>{{shipmentType}}</span>
    </div>
    <div style="display: flex; align-items: center; margin-right: 100px;">
      <img alt="Truck Description" class="icon" src="assets/img/ic_shipment_info_tick.svg" />
      <span>{{getTruckTypeInfo()}}</span>
    </div>
    <div *ngIf="displayInfo.routeMileage || displayInfo.routeHours" style="display: flex; align-items: center;">
      <img class="icon icon-route-mileage-hour" src="/assets/img/dispatch-icons/route.svg" />
      <span>{{ displayInfo.routeMileage }} mi - {{ displayInfo.routeHours }} hr</span>
    </div>
  </div>
</div>
