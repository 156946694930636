import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Const } from "@const/Const";
import { TAGS } from "./const";

@Component({
    selector: '[tag-order-form]',
    templateUrl: './index.html',
    styleUrls: ['index.scss']
})
export class TagOrderForm extends BaseComponent {
    _order: any = null;
    _tags: any[] = []
    _hiddenTags: any[] = []
    onClose: any

    @Output() onTagsSaved: EventEmitter<any> = new EventEmitter<any>()

    @Input() set order(v) {
        this._order = v
        this._tags = this.allTags.map(it => Object.assign({}, it, { selected: v.tags && v.tags.indexOf(it.key) >= 0 }))
        const shown = this.allTags.map(it => it.key)
        this._hiddenTags = (v.tags || []).filter(it => shown.indexOf(it) < 0)
    }

    get allTags() {
        return TAGS
    }

    saving = false
    onSave() {
        const tags = this._tags.filter(it => it.selected).map(it => it.key).concat(this._hiddenTags)
        this.saving = true
        this.api.POST(`${Const.APIV2(Const.APIURI_ORDERS)}/${this._order.id}/tag`, {tags}).subscribe(
            (resp) => {
                this._order.tags = tags;
                this.saving = false
                this.onTagsSaved.emit(tags)
                this.onClose()
            },
            (err) => {
                this.saving = false
                this.showErr(err);
            }
        );

    }
}