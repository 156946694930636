import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";

@Component({
  selector: '[manual-route]',
  templateUrl: './index.html',
  styleUrls: ['../../../dialogs/dialogs.scss', './index.scss']
})
export class ManualRoute extends BaseDialog {
  private _order: any;
  public shipment: any

  @Input() get order() { return this._order }
  set order(value) {
    this._order = value ?? {};
    this.shipment = value?.metadata?.shipments?.[0];
    if (this.shipment.deliveryInfos) {
      this.shipment.addrPick = this.getAddressText(this.shipment.deliveryInfos.filter(it => it.type == Const.TaskType.PICKUP)[0]?.addr);
      this.shipment.addrDrop = this.getAddressText(this.shipment.deliveryInfos.filter(it => it.type == Const.TaskType.DROPOFF)[0]?.addr);
    } else {
      this.shipment.addrPick = this.getAddressText(this.shipment.pickAddr || this.shipment.pickInfo?.addr);
      this.shipment.addrDrop = this.getAddressText(this.shipment.dropAddr || this.shipment.dropInfo?.addr);
    }
  }
  onDone: (data) => void = (data) => { }
  onBtnRefresh: () => void = () => { };

  protected formGroupDeclaration: FormGroupDeclaration = {
    depot: { label: 'Return to Depot', placeHolder: 'Return to Depot', initialValue: false, required: true }
  }

  // public isLoading = false;
  public get job() { return this.listJobs[0] };
  public inProgress = false;
  public returnToDepot: boolean = false;
  public listJobs = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.getData(this.order.id);
  }

  onBtnCancel() {
    this.closeDialog();
  }
  
  onOk() {
    if (!this.job) {
      this.onStartRouting();
    } else {
      this.closeDialog();
      this.onBtnRefresh();
    }
  }

  onReturnToDepot(orderId) {
    if (this.isAdminReadOnlyRole) return;
    // this.closeDialog();
    // DialogService.openFormDialog1(ReturnToDepotShipment, {
    //   nzComponentParams: {
    //     orderId: orderId,
    //     closeOnSuccess: true,
    //     createSuccess: resp => {
    //       let msg = 'Clone shipment successfully.';
    //       if (Utils.isStringNotEmpty(resp?.message)) {
    //         msg = resp.message;
    //       }
    //       this.showDialog(msg, () => this.onBtnRefresh());
    //     }
    //   },
    //   nzClassName: 'modal-no-padding modal-xl',
    // });
    this.onStartRouting({ returnToDepot: true })
  }

  onStartRouting({ returnToDepot } = { returnToDepot: false }) {
    if (this.inProgress) return;
    this.inProgress = true;
    const url = Const.APIURI_MANUAL_ROUTE;
    const payload = {
      returnToDepot: returnToDepot,
      orderId: this.order.id
    }
    this.api.POST(url, payload).subscribe(
      resp => {
        let job;
        job = resp.data;
        this.inProgress = false;
        this.listJobs.push(job);
        if (!this.order.jobIds) {
          this.order.jobIds = [];
        }
        this.order.jobIds.push(job.id);
      }, err => {
        this.inProgress = false;
        this.showErr(err);
      }
    );
  }

  public get mileage(): string {
    const { totalDistance } = this.job || {}
    if (!totalDistance) return ''
    return (totalDistance / 1609.34).toFixed(2) + ' mi'
  }

  public get hours(): string {
    const { totalTime } = this.job || {}
    if (!totalTime) return ''
    return ((totalTime / 3600).toFixed(2)) + ' h'
  }

  private getData(orderId) {
    this.inProgress = true;
    const url = `${Const.APIURI_ORDERS}/${orderId}/jobs`;
    this.api.GET(url).subscribe(
      resp => {
        this.listJobs = resp?.data?.list_data ?? [];
        this.inProgress = false;
      }, err => {
        this.inProgress = false;
        this.showErr(err);
      }
    );
  }

  onHold() {
    return this.order.tags && this.order.tags.indexOf('HOLD') >= 0;
  }
}
