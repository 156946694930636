import { Component, Input } from '@angular/core';
import { BaseDrawer } from '@app/drawers/base-drawer';
import { BizUtil } from '@services/biz';
import { ShipmentItem } from '@wearewarp/types/data-model';
import { NzTreeNodeOptions } from 'ng-zorro-antd/tree';

@Component({
  selector: 'view-content-item',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ViewContentItem extends BaseDrawer {
  @Input() itemIndex = 0;

  private _item: ShipmentItem;
  @Input() get item(): ShipmentItem { return this._item }
  set item(value: ShipmentItem) {
    this._item = value;
    const node: NzTreeNodeOptions = this.convertObjectToTree(value, this.itemIndex);
    this.treeData = [node];
  }
  treeData: NzTreeNodeOptions[] = [];
  ngOnInit(): void {
    super.ngOnInit();
  }

  constructor() {
    super();
  }

  convertObjectToTree(value: ShipmentItem, itemIndex: number): NzTreeNodeOptions {
    let node: NzTreeNodeOptions = {key: null, title: ''};
    node.item = value;
    node.key = value.id;
    node.title = this.getTitleItem(value, itemIndex);
    node.expanded = true;
    node.children = [];
    for (let i = 0; i < value.children.length; i++) {
      let child = value.children[i];
      let itemChild = this.convertObjectToTree(child, i);
      node.children.push(itemChild);
    }
    return node;
  }

  getTitleItem(item: ShipmentItem, index: number): string {
    const itemName = item.name || ('Item ' + (index + 1));
    const itemDesc = BizUtil.getItemDescWithoutName(item);
    return `${itemName}, ${itemDesc}`;
  } 

}
