import { Component, EventEmitter, Input, OnInit, Output, Injector } from "@angular/core";
import { FormDataShipmentEntryDraftSingle } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";
import { ApiService } from "@services/api.service";
import { getInjector } from "@services/index";
import { Const } from "@const/Const";
import { ResponseAdminOrderDetail } from "@wearewarp/types/rest-api/admin";
@Component({
  selector: "detail-single-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class DetailSingleInfo implements OnInit {
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshDetailOrder: EventEmitter<any> = new EventEmitter<any>();
  isEmpty: boolean = true;
  customerAndEquipment: any;
  isTurnOnEditMode = Const.defaultTurnOnEditMode;

  protected api: ApiService;
  protected injector: Injector;
  listClients = [];
  locations = [];

  private _model: ResponseAdminOrderDetail;
  @Input() get model(): any {
    return this._model;
  }
  set model(value) {
    this._model = value;
    if (this.model?.basicInfo) {
      this.setCustomerAndEquipment(this.model.basicInfo);
    }
    if (this._model?.metadata?.shipments) {
      this.locations = this._model?.metadata?.shipments?.flatMap((s) => s.deliveryInfos ?? []);
    }
  }
  constructor() {
    this.injector = getInjector();
    this.api = this.injector.get(ApiService);
  }

  ngOnInit(): void {}

  setCustomerAndEquipment(data) {
    this.customerAndEquipment = data;
    this.checkEmptyState();
  }

  checkEmptyState() {
    this.isEmpty = this.customerAndEquipment == null;
  }

  onClickEdit() {
    this.onEdit.emit();
  }

  onBtnRefreshDetailOrder() {
    this.refreshDetailOrder.emit();
  }

  onEditModeChange(event) {
    this.isTurnOnEditMode = !!event;
    this.onEdit.emit(this.isTurnOnEditMode);
  }
}
