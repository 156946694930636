<div class="shipment-entry-content">
  <div style="flex: 1; margin-right: 20px;">
    <div style="background: #FFFFFF;">
      <route-detail-batch [isTurnOnEditMode]="isTurnOnEditMode" [model]="model"
        (refreshDetailOrder)="onBtnRefreshDetailOrder()"></route-detail-batch>
    </div>

    <div style="margin-top: 20px; background: #FFFFFF;">
      <shipment-entry-revenue-info-v2 [model]="model" [isTurnOnEditMode]="isTurnOnEditMode"
        (refreshDetailOrder)="onBtnRefreshDetailOrder()"></shipment-entry-revenue-info-v2>
    </div>

    <div *ngIf="shouldShowRevenueSummary" style="margin-top: 20px; background: #FFFFFF;">
      <shipment-entry-revenue-summary [model]="model"></shipment-entry-revenue-summary>
    </div>
    <div class="order-issue-box">
      <div class="title">Order Issue</div>
      <div notes-component [subjectId]="model.id" [subjectType]="'orderIssue'" [allowDeletion]="true"
        [allowDeleteItemNotMine]="true" [hasUploadFile]="true">
      </div>
    </div>
  </div>
  <div style="width: 330px;">
    <detail-single-info (onEdit)="onEditModeChange($event)" [model]="model"
      (refreshDetailOrder)="onBtnRefreshDetailOrder()"></detail-single-info>
  </div>
</div>