<div class="box">
  <ng-container *ngIf="isEmpty">
    <empty-info></empty-info>
  </ng-container>
  <div batch-map [infos]="locations"></div>
  <ng-container *ngIf="customerAndEquipment">
    <customer-equipment-info [model]="customerAndEquipment" [modelOrder]="model" [hasTitle]="true"
      [isTurnOnEditMode]="isTurnOnEditMode" (refreshDetailOrder)="onBtnRefreshDetailOrder()">
    </customer-equipment-info>
  </ng-container>
  <action-info [model]="model" (onEdit)="onEditModeChange($event)"></action-info>
</div>