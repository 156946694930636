import { BaseComponent } from "@abstract/BaseComponent";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Const } from "@const/Const";

@Component({
  selector: "action-info",
  templateUrl: "./view.html",
  styleUrls: ["./style.scss"],
})
export class ShipmentActionInfo extends BaseComponent implements OnInit {
  @Input("model") model: any;
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();

  private isTurnOnEditMode = Const.defaultTurnOnEditMode;

  ngOnInit(): void {}

  // Lấy status của bản ghi order, không phải shipment, không cho edit
  get orderStatus() {
    return Const.getOrderStatusTextNew(this.model?.status);
  }
  set orderStatus(value) {
    if (!this.model) return;
    this.model.status = value;
  }

  get labelEditBtn() {
    return this.isTurnOnEditMode ? "View Mode" : "Edit Mode";
  }
  onClickEdit() {
    this.isTurnOnEditMode = !this.isTurnOnEditMode;
    if (!this.isAdminReadOnlyRole) this.onEdit.emit(this.isTurnOnEditMode);
  }

  get shouldShowEditModeBtnShipmentEntry() {
    return Const.shouldShowEditModeBtnShipmentEntry;
  }
}
