import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { OrderfulInfoDialog } from '.';
import { DetailModule } from '@app/admin/base/detail.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzRadioModule,
    NzIconModule,
    NzTableModule,
    DetailModule,
  ],
  declarations: [
    OrderfulInfoDialog,
  ],
  exports: [
    OrderfulInfoDialog,
  ]
})
export class OrderfulDialogModule {}